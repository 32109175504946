import React, { useState, FormEvent } from 'react'
import { NextPage } from 'next'
import Head from 'next/head'
import Link from 'next/link'
import { useFormState } from 'react-use-form-state'
import { useAmplitude } from 'react-amplitude-hooks'

import { Me } from '../interfaces/auth'
import useUser from '../utils/hooks/useUser'
import fetchJson, { createBody } from '../utils/fetchJson'
import Layout from '../components/Layout'
import Input from '../components/Input'
import Button from '../components/Button'
import Card from '../components/Card'
import Title from '../components/Title'
import LogoWithText from '../components/LogoWithText'

type FormValues = {
  email: string
  password: string
}

const LoginPage: NextPage = () => {
  const { mutateUser } = useUser({
    redirectTo: '/',
    redirectIfFound: true,
  })

  const { logEvent } = useAmplitude()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [formState, { email, password }] = useFormState<FormValues>()

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setIsLoading(true)

    try {
      const user = await fetchJson<Me>(
        '/api/auth/login',
        createBody(formState.values)
      )
      logEvent('Login', { email: formState.values.email })
      mutateUser(user, false)
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Layout pageName="Login" withSideNav={false}>
      <Head>
        <title>Login | userstoriz</title>
      </Head>
      <div className="flex flex-col items-center mt-16 mb-8">
        <div className="mb-8">
          <Link href="/login">
            <a>
              <LogoWithText style={{ width: 140 }} />
            </a>
          </Link>
        </div>
        <Card style={{ width: 450 }}>
          <Title>Sign in to your account</Title>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col space-y-8 mt-4"
          >
            <Input
              {...email('email')}
              label="Email"
              placeholder="hello@userstoriz.com"
              autoComplete="email"
              isRequired
              tabIndex={1}
            />
            <Input
              {...password('password')}
              label="Password"
              placeholder="••••••••••••"
              autoComplete="current-password"
              isRequired
              tabIndex={2}
              extra={
                <Link href="/password/forgot">
                  <a className="text-primary font-medium text-sm" tabIndex={3}>
                    Forgot your password?
                  </a>
                </Link>
              }
            />
            <Button
              isFullWidth
              appearance="primary"
              isLoading={isLoading}
              tabIndex={4}
            >
              Login
            </Button>
          </form>
          {errorMessage && <p className="mt-2 text-red-700">{errorMessage}</p>}
          <div className="flex justify-center mt-8 text-xs text-secondary">
            Don’t have an account?
            <Link href="/signup">
              <a className="ml-1 text-primary">Signup</a>
            </Link>
          </div>
        </Card>
        <div className="text-xs text-secondary mt-5">
          © {new Date().getFullYear()} userstoriz. All rights reserved.
        </div>
      </div>
    </Layout>
  )
}

export default LoginPage
