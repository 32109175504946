import React, { FC } from 'react'

const Title: FC = ({ children, ...props }) => (
  <div
    className="title font-heading font-semibold text-2xl mb-6 pb-2"
    {...props}
  >
    <span className="relative">{children}</span>
  </div>
)

export default Title
