import React, { SVGProps } from 'react'

const LogoWithText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="181"
    height="32"
    fill="none"
    viewBox="0 0 181 32"
    {...props}
  >
    <g clipPath="url(#clip0_logoWithText)">
      <g clipPath="url(#clip1_logoWithText)">
        <path
          fill="#546DE5"
          fillRule="evenodd"
          d="M24.058 29.479V6.196H26v25.381H4.239C1.898 31.577 0 29.637 0 27.243v-6.156l8.458-5.695a5.435 5.435 0 005.495-.346 5.437 5.437 0 002.405-4.712.389.389 0 00-.217-.338.386.386 0 00-.401.023l-2.561 1.724-1.93-1.465-.632-2.34 2.562-1.724a.401.401 0 00.172-.363.394.394 0 00-.235-.329 5.435 5.435 0 00-5.266.457A5.434 5.434 0 005.46 10.94L0 14.617V4.334C0 1.94 1.898 0 4.239 0h16.303l.249.266v23.832H4.253a2.47 2.47 0 00-2.47 2.47v.44a2.47 2.47 0 002.47 2.47h19.805z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#5973FF"
          d="M3.549 26.835c0 .683.47 1.238 1.05 1.238h16.29v-2.477H4.598c-.58 0-1.05.555-1.05 1.239z"
        ></path>
      </g>
      <path
        fill="url(#paint0_linear)"
        d="M39.696 26.789c-1.813 0-3.221-.507-4.224-1.52C34.491 24.232 34 22.733 34 20.77V11.13h5.152v9.35c0 .863.203 1.499.608 1.909.405.388 1.035.582 1.888.582.597 0 1.173-.119 1.728-.356a4.528 4.528 0 001.408-1.003V11.13h5.152v15.335h-4.8l-.16-1.294c-1.43 1.079-3.19 1.618-5.28 1.618z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        d="M58.823 26.789c-1.194 0-2.336-.087-3.424-.26-1.088-.172-1.962-.387-2.624-.646v-3.98c.726.302 1.568.55 2.528.745.982.172 1.878.258 2.688.258 1.003 0 1.686-.043 2.048-.13.384-.107.576-.344.576-.71 0-.303-.181-.55-.544-.745-.362-.194-1.002-.431-1.92-.712l-1.376-.453c-1.493-.517-2.57-1.11-3.232-1.78-.661-.69-.992-1.65-.992-2.879 0-3.127 2.347-4.69 7.04-4.69.918 0 1.846.075 2.784.226.939.13 1.728.302 2.368.518v3.914c-.576-.259-1.248-.453-2.016-.582a10.675 10.675 0 00-2.112-.227c-.938 0-1.642.054-2.112.162-.47.108-.704.334-.704.68 0 .302.15.528.448.679.299.13.875.323 1.728.582.427.108.864.238 1.312.389 1.195.366 2.123.765 2.784 1.197.662.41 1.13.905 1.408 1.488.278.582.416 1.316.416 2.2 0 1.553-.608 2.739-1.824 3.559-1.194.798-2.944 1.197-5.248 1.197z"
      ></path>
      <path
        fill="url(#paint2_linear)"
        d="M76.632 26.789c-2.71 0-4.854-.68-6.432-2.039-1.558-1.358-2.336-3.343-2.336-5.953 0-2.501.672-4.453 2.016-5.855 1.365-1.424 3.338-2.136 5.92-2.136 2.368 0 4.224.626 5.568 1.877 1.344 1.25 2.016 2.933 2.016 5.047v2.75H72.632c.234.992.768 1.693 1.6 2.103.832.41 2.026.615 3.584.615.832 0 1.674-.076 2.528-.227.874-.15 1.6-.345 2.176-.582v3.397c-1.45.668-3.414 1.003-5.888 1.003zm2.272-9.318v-.68c0-1.639-.982-2.458-2.944-2.458-1.216 0-2.08.248-2.592.744-.49.496-.736 1.294-.736 2.394h6.272z"
      ></path>
      <path
        fill="url(#paint3_linear)"
        d="M86.004 11.13H90.9l.16 1.423c.682-.41 1.514-.776 2.496-1.1.981-.345 1.952-.56 2.912-.647v3.915a20.02 20.02 0 00-2.88.582c-1.024.26-1.835.54-2.432.842v10.32h-5.152V11.13z"
      ></path>
      <path
        fill="url(#paint4_linear)"
        d="M104.073 26.789a21.86 21.86 0 01-3.424-.26c-1.088-.172-1.962-.387-2.624-.646v-3.98c.726.302 1.568.55 2.528.745.982.172 1.878.258 2.688.258 1.003 0 1.686-.043 2.048-.13.384-.107.576-.344.576-.71 0-.303-.181-.55-.544-.745-.362-.194-1.002-.431-1.92-.712l-1.376-.453c-1.493-.517-2.57-1.11-3.232-1.78-.661-.69-.992-1.65-.992-2.879 0-3.127 2.347-4.69 7.04-4.69.918 0 1.846.075 2.784.226.939.13 1.728.302 2.368.518v3.914c-.576-.259-1.248-.453-2.016-.582a10.666 10.666 0 00-2.112-.227c-.938 0-1.642.054-2.112.162-.469.108-.704.334-.704.68 0 .302.15.528.448.679.299.13.875.323 1.728.582.427.108.864.238 1.312.389 1.195.366 2.123.765 2.784 1.197.662.41 1.131.905 1.408 1.488.278.582.416 1.316.416 2.2 0 1.553-.608 2.739-1.824 3.559-1.194.798-2.944 1.197-5.248 1.197z"
      ></path>
      <path
        fill="url(#paint5_linear)"
        d="M119.994 26.789c-1.835 0-3.211-.475-4.128-1.424-.896-.949-1.344-2.297-1.344-4.044v-6.244h-2.048V11.13h2.048V7.927l5.152-1.391v4.594h3.68l-.224 3.947h-3.456v5.888c0 .733.17 1.251.512 1.553.341.28.896.42 1.664.42.618 0 1.248-.107 1.888-.323v3.527c-1.003.431-2.251.647-3.744.647z"
      ></path>
      <path
        fill="url(#paint6_linear)"
        d="M132.814 26.789c-5.696 0-8.544-2.664-8.544-7.992 0-2.588.704-4.561 2.112-5.92 1.429-1.38 3.573-2.07 6.432-2.07 2.88 0 5.024.679 6.432 2.038 1.429 1.358 2.144 3.343 2.144 5.952 0 5.328-2.859 7.992-8.576 7.992zm0-4.077c1.195 0 2.048-.302 2.56-.906.512-.604.768-1.607.768-3.009 0-1.401-.267-2.404-.8-3.008-.512-.604-1.355-.906-2.528-.906s-2.016.313-2.528.938c-.512.604-.768 1.596-.768 2.976 0 1.402.256 2.405.768 3.01.512.603 1.355.905 2.528.905z"
      ></path>
      <path
        fill="url(#paint7_linear)"
        d="M144.004 11.13h4.896l.16 1.423c.682-.41 1.514-.776 2.496-1.1.981-.345 1.952-.56 2.912-.647v3.915a20.02 20.02 0 00-2.88.582c-1.024.26-1.835.54-2.432.842v10.32h-5.152V11.13z"
      ></path>
      <path
        fill="url(#paint8_linear)"
        d="M158.265 4.789h5.6v3.914h-5.6V4.79zm.416 10.19h-2.24l.448-3.85h6.944v15.336h-5.152V14.98z"
      ></path>
      <path
        fill="url(#paint9_linear)"
        d="M166.489 23.683l7.008-8.412h-6.752V11.13h13.28v3.041l-6.848 8.282h7.072v4.012h-13.76v-2.782z"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="32.528"
        x2="181.528"
        y1="20.399"
        y2="20.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#383838"></stop>
        <stop offset="1" stopColor="#1E1E1E"></stop>
      </linearGradient>
      <clipPath id="clip0_logoWithText">
        <path fill="#fff" d="M0 0H180.249V31.577H0z"></path>
      </clipPath>
      <clipPath id="clip1_logoWithText">
        <path fill="#fff" d="M0 0H26V31.577H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default LogoWithText
